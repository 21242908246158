import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { tap, distinctUntilChanged, debounceTime, takeUntil } from "rxjs/operators";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-search-bar",
	templateUrl: "app-search-bar.component.html",
	styleUrls: ["app-search-bar.css"]
})
export class SearchBarComponent implements OnInit, OnChanges, OnDestroy {
	@Input() placeholder: string = "Search...";
	@Input() isUserSearch: boolean = false;

	@Output() searchText = new EventEmitter<string>();
	@Output() closeCallback = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public search = new FormControl();

	private destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		this.search.valueChanges
			.pipe(
				takeUntil(this.destroy$),
				debounceTime(400),
				distinctUntilChanged(),
				tap((value) => this.searchText.emit(value))
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.placeholder) {
			this.clearSearch();
		}
	}

	ngOnDestroy(): void {
		this.clearSearch();
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	public clearSearch(): void {
		this.search.reset();
	}

	public close(): void {
		this.closeCallback.emit();
	}
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ListColumn } from "src/app/dto/ui/list-column";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-blue-list",
	templateUrl: "blue-list.component.html",
	styleUrls: ["blue-list.css"]
})
export class BlueListComponent implements OnInit {
	@Input() dataArr: Array<any> = [];
	@Input() columns: Array<ListColumn> = [];
	@Input() isFiltering = false; // to identify if this.dataArr is a result of filterring in closed incidents for the message "No Results found"
	@Input() kebabMenu: boolean = false;

	@Output() typeFilterEvent: EventEmitter<string[]> = new EventEmitter();
	@Output() sortEvent: EventEmitter<{ attributeName: string; direction: string; attributeType: string }> = new EventEmitter();
	@Output() reopenIncident = new EventEmitter<void>();

	@ViewChild("funnel") funnelEl!: ElementRef;

	public readonly text: () => LocaleMap;
	public filters: { [key: string]: any } = {};
	public showSortAndFilter = false;
	public clickedIndex: number = -1;
	public reopenIconUrl = "resources/img/reopen-icon.svg";
	public deleteIconUrl = "resources/img/delete-bin-off.svg";

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		this.resetAllFilters();
	}

	public readonly getFilterStatus = (column: ListColumn): { [key: string]: any } => {
		return this.filters[column.colTag];
	};

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public readonly getColumnColor = (item: any, column: ListColumn): { backgroundColor: string } => {
		return { backgroundColor: item[column.attrName + "Color"] as string };
	};

	public readonly getColumnWidth = (column: ListColumn): { width: string } => {
		return { width: column.width };
	};

	public readonly hasCallbacks = (item: { downloadCallback: Function; deleteCallback: Function; kebabCallback: Function }): boolean => {
		if ((item && item.downloadCallback) || (item && item.deleteCallback) || (item && item.kebabCallback)) return true;
		return false;
	};

	public readonly closeModal = (): boolean => {
		return (this.showSortAndFilter = false);
	};

	public isTypeFilterApplied = (typeCol: ListColumn): boolean => {
		return this.filters[typeCol.colTag].sortDirection || (this.filters[typeCol.colTag].filterValues && this.filters[typeCol.colTag].filterValues.length);
	};

	public readonly setTypeFilter = (filter: { sortDirection: string; filterValues: string[] }): void => {
		let typeCol = this.columns.find((e) => e.isType);
		if (!typeCol) return;
		this.clearAllFilters();
		this.filters[typeCol.colTag] = filter;
		this.applyfilter();
	};

	public readonly setSortFilter = (column: ListColumn): void => {
		const prevStatus = this.filters[column.colTag];
		this.clearAllFilters();
		if (prevStatus === "asc") this.filters[column.colTag] = "desc";
		if (!prevStatus || prevStatus === "desc") this.filters[column.colTag] = "asc";
		this.applyfilter();
	};

	public readonly applyfilter = (): void => {
		for (const filterName in this.filters) {
			if (this.filters[filterName]) {
				const attrName = this.columns.filter((col) => col.colTag === filterName)[0].attrName;
				if (typeof this.filters[filterName] === "object") {
					this.filters[filterName]["filterValues"] && this.typeFilterEvent.emit(this.filters[filterName]["filterValues"]);
					this.filters[filterName]["sortDirection"] && this.sortEvent.emit({ attributeName: attrName, direction: this.filters[filterName].sortDirection, attributeType: "string" });
				}
				if (typeof this.filters[filterName] === "string") {
					const dataType = attrName === "number" ? "number" : "string";
					this.sortEvent.emit({ attributeName: attrName, direction: this.filters[filterName], attributeType: dataType });
				}
			}
		}
	};

	public readonly clearAllFilters = (): void => {
		for (const key in this.filters) this.filters[key] = "";
	};

	private readonly resetAllFilters = (): void => {
		this.columns.forEach((column) => (this.filters[column.colTag] = ""));
		this.typeFilterEvent.emit([]);
	};
}

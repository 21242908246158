<div class="main-black-mask" (click)="cancel()"></div>
<div class="history-modal">
	<div class="title">
		{{ title }}
		<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
	</div>

	<div class="subtitle" *ngIf="currentItem">
		<div>
			<div class="subtitle-icon">
				<img src="resources/img/jesip/clock.svg" />
			</div>
			<span>{{ currentItem.timestamp_ms | date : "hh:mm a" }}</span>
		</div>
		<div>
			<div class="subtitle-icon">
				<img src="resources/img/calendar.svg" />
			</div>
			<span>{{ currentItem.timestamp_ms | date : "dd/MM/YYYY" }}</span>
		</div>
		<div class="pointer" (click)="downloadHistoric()">
			<div class="subtitle-icon"><img src="resources/img/jesip/download.svg" /></div>
			{{ text().DOWNLOAD }}
		</div>
	</div>
	<div class="divider"></div>

	<div class="jesip-body">
		<!-- ****************** LIST AREA ******************** -->
		<app-jesip-list
			[items]="itemList"
			class="jesip-list"
			[isDisabled]="true"
			[isJdm]="isJdm"
		></app-jesip-list>
		<!-- ****************** END ******************** -->

		<!-- ****************** SIDE PANE ******************** -->
		<div class="information-column">
			<div class="incident-title">{{ text().INCIDENT_INFORMATION }}</div>

			<!-- ****************** METHANE ******************** -->
			<div *ngIf="formType === jesipForms.METHANE" class="width-92">
				<div class="general-information-top">
					<img src="resources/img/jesip/jesip-telephone-icon.svg" />
					<span>{{ this.text().FIRST_CALLER_INFO }}</span>
				</div>

				<div class="text-field-container">
					<app-simple-text-field
						[type]="'text'"
						[data]="firstCallerName()"
						[placeholderText]="'Name'"
						[label]="'Name'"
						[isDisabled]="true"
					>
					</app-simple-text-field>
				</div>
				<div class="text-field-container">
					<div class="transparent-dropdown-filter">
						<div class="transparent-dropdown-filter-text">
							<div class="input-span">
								<input
									placeholder="Organisation"
									[value]="firstCallerOrg()"
									readonly
								/>
							</div>
							<div class="dropdown-arrow-mask">
								<div class="dropdown-triangle down"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- ****************** END ******************** -->

			<!-- ****************** JDM ******************** -->
			<div *ngIf="formType === jesipForms.JDM" class="width-92">
				<div class="general-information-top">
					<div class="img-container">
						<img src="resources/img/jesip/location-pin.svg" />
					</div>
					<span>{{ this.text().GENERAL_INFORMATION }}</span>
				</div>
				<div class="text-field-container">
					<app-simple-text-box
						*ngIf="info"
						[type]="'text'"
						[data]="info.location!"
						[placeholderText]="text().LOCATION3"
						[label]="text().LOCATION3"
						[rows]="1"
						[isDisabled]="true"
						[labelBackground]="'#051c2a'"
						[maxHeight]="96"
						[padding]="16"
					>
					</app-simple-text-box>
				</div>
				<div class="text-field-container">
					<app-simple-text-box
						*ngIf="info"
						[type]="'text'"
						[data]="info.type!"
						[placeholderText]="text().TYPE_OF_INCIDENT"
						[label]="text().TYPE_OF_INCIDENT"
						[rows]="1"
						[isDisabled]="true"
						[labelBackground]="'#051c2a'"
						[maxHeight]="96"
						[padding]="16"
					>
					</app-simple-text-box>
				</div>
			</div>
			<!-- ****************** END ******************** -->

			<!-- ****************** COMMANDERS ******************** -->
			<app-team-list
				*ngIf="commanders && commanders.length"
				[header]="text().COMMANDER_INFO"
				[members]="commanders"
				[headerIcon]="'resources/img/jesip/commander-icon.svg'"
				[memberTitle]="text().COMMANDER"
				[addText]="text().ADD_TEAM_MEMBER"
				[isDisabled]="true"
				[historyMode]="true"
			>
			</app-team-list>
			<!-- ****************** END ******************** -->
		</div>
	</div>

	<div class="display-flex margin-16">
		<app-simple-pagination
			[totalPages]="data.length"
			(changePage)="setJesipItem($event - 1)"
		></app-simple-pagination>
	</div>
</div>

<div class="container">
	<div class="section">
		<header class="section-header" [class.bold]="boldHeader">
			{{ header }}
			<div *ngIf="helpText" class="help-icon"></div>
			<ng-container *ngIf="helpText">
				<div class="help-tooltip-container">
					<div class="help-tooltip">{{ helpText }}</div>
				</div>
			</ng-container>
		</header>
		<div class="section-list">
			<ng-container *ngFor="let item of items; let i = index">
				<div class="item" [class.expanded]="item.isExpanded || item.value">
					<div
						data-add-action-name="debrief-item-list"
						class="item-tops"
						(click)="toggleItem(i)"
					>
						<div *ngIf="item" class="number" [class.filled]="item.value">
							{{ item.id }}
						</div>
						<div>
							<div *ngIf="item.header" class="item-header bold">
								{{ item.header }}
							</div>
							<div class="text">{{ item.text }}</div>
						</div>
					</div>
					<div *ngIf="item.isExpanded || item.value" class="input-container">
						<textarea
							data-cy="debrief-input"
							#inputElement
							appAutoResize
							appDetectClickOut
							(callback)="onMousedownOutside($event)"
							[id]="'input_' + item.id"
							[rows]="1"
							[(ngModel)]="item.value"
							(ngModelChange)="itemUpdate.emit(item)"
							[placeholder]="placeholder"
							(keydown)="onKeyPressed($event, inputElement, item)"
						></textarea>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>

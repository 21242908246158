import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Level } from "src/app/dto/user/level";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { UserService } from "src/app/settings/user/user.service";
import { forbiddenNameValidator } from "./settings-modal.validators";
import { MainService } from "src/app/global/main.service";
import { FUNCTIONALITY, FunctionalityService } from "src/app/global/functionality.service";

const ADMIN = "ADMIN";

@Component({
	selector: "app-settings-modal",
	templateUrl: "settings-modal.component.html",
	styleUrls: ["settings-modal.css"]
})
export class SettingsModalComponent implements OnInit {
	@Input() headerText: string = "";
	@Input() selectText: string = "";
	@Input() optionText: string = "";
	@Input() level: Level = new Level(-1, "");
	@Input() edited: boolean = false;

	@Output() cancelCallback = new EventEmitter();
	@Output() confirmCallback = new EventEmitter();

	public Levels: Array<Level>;
	public levelForm!: FormGroup;
	public currentTab: "description" | "permissions" = "description";
	public selectedOption: string = "";
	public done: boolean = false;
	public selected: boolean = false;
	public isAnimationComplete = false;
	public isComplete = false;
	public permissions: Permission[] = [];
	public groups: Array<PermissionGroup> = [];
	public name = "";
	public selectedIndex: number = -1;
	public levelDescription: string = "";

	public twoOptionDialogParams: {
		show: boolean;
		header: string;
		body: string;
		leftText: string;
		rightText: string;
		cancelCallback: Function;
		leftCallback: Function;
		rightCallback: Function;
	} = {
		show: false,
		header: "",
		body: "",
		leftText: "",
		rightText: "",
		cancelCallback: () => {},
		leftCallback: () => {},
		rightCallback: () => {}
	};
	public readonly text: () => LocaleMap;

	private mappedPermissions: Map<string, string> = new Map();
	private originalPermissions: Map<string, boolean> = new Map();

	private permissionDependencies: Record<string, Record<string, string[]>> = {};

	private readonly userService: UserService;
	private readonly main: MainService;
	private readonly func: FunctionalityService;

	constructor(textProv: TextProvider, userService: UserService, main: MainService, func: FunctionalityService) {
		this.text = textProv.getStringMap;
		this.userService = userService;
		this.Levels = this.userService.Levels;
		this.selectedIndex = this.Levels.indexOf(this.level);
		this.main = main;
		this.func = func;
	}

	ngOnInit(): void {
		this.levelForm = new FormGroup(
			{
				level: new FormControl(this.level ? this.level?.name : "", [Validators.required, forbiddenNameValidator(this.userService.Levels, this.level?.name)]),
				description: new FormControl(this.level ? this.level?.description : "")
			},
			{
				validators: [],
				updateOn: "change"
			}
		);

		this.buildPermissionDependencies();
		this.getPermissions();
		this.initializeData();
		this.Levels = this.userService.Levels;
	}

	public isAdmin(level: Level): boolean {
		return level.name.toLocaleUpperCase() === ADMIN;
	}

	public buildPermissionDependencies(): void {
		this.permissionDependencies = {
			configure_ara: { on: ["view_ara", "create_review_ara"], off: [] },
			create_review_ara: { on: ["view_ara"], off: ["configure_ara"] },
			view_ara: { on: [], off: ["configure_ara", "create_review_ara"] },
			delete_ef: { on: ["view_ef"], off: [] },
			edit_ef: { on: ["view_ef"], off: [] },
			create_dl: { on: ["view_dl"], off: [] }
		};

		if (this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT) && this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE)) {
			this.permissionDependencies.create_review_ara.off.push("sign_ara");
			this.permissionDependencies.sign_ara = { on: ["view_ara", "create_review_ara"], off: [] };
			this.permissionDependencies.view_ara.off.push("sign_ara");
		}
	}

	public toggleCheckbox(permission: Permission): void {
		permission.value = !permission.value;

		const onOrOff: Record<string, string[]> = this.permissionDependencies[permission.property];
		if (onOrOff) {
			const dependenciesToChange = permission.value ? onOrOff.on : onOrOff.off;
			for (const dependency of dependenciesToChange) {
				let p = this.permissions.find((permission) => permission.property === dependency);
				if (p) p.value = permission.value;
			}
		}
	}

	public isDisabled(): boolean {
		let permissionsChanged = false;

		for (const [property, value] of this.originalPermissions.entries()) {
			const permissionObject = this.permissions.find((permission) => permission.property === property);
			if (value !== permissionObject!.value) permissionsChanged = true;
		}

		return !permissionsChanged && this.name.toLowerCase() === this.level.name.toLowerCase();
	}

	public saveLevel(level: Level): void {
		let newLevelName = this.levelForm.controls["level"].value;
		let newLevelDescription = this.levelForm.controls["description"].value;
		this.level.name = newLevelName;
		this.level.description = newLevelDescription;

		this.selectedIndex = this.Levels.indexOf(this.level);
		this.permissions.forEach((permission) => ((this.level[permission.property as keyof Level] as boolean) = permission.value));

		const errorBlock = () => {
			newLevelName = this.level.name;
			this.originalPermissions.forEach((value, property) => ((this.level[property as keyof Level] as boolean) = value));
			this.main.addDangerAlert(this.text().SAVE_ERROR);
		};

		this.userService.saveLevel(level).then(
			(success) => {
				if (success) {
					this.cancelCallback.emit();
					this.main.setSnackbar(`${this.text().PERMISSION_LEVEL} "${level.name}" ${this.text().UPDATED}`);
					this.initializeData();
				} else {
					errorBlock();
				}
			},
			() => errorBlock()
		);
	}

	public setLevel(value: string): void {
		this.selectedOption = value;
		this.levelForm.get("level")?.setValue(value);
	}

	public isEdited(): boolean {
		if (this.levelForm.controls["level"].value === this.level.name && this.levelForm.controls["description"].value === this.level.description) return (this.edited = false);
		else return (this.edited = true);
	}

	public readonly cancel: Function = () => {
		if (this.hasChanged()) {
			this.twoOptionDialogParams = {
				show: true,
				header: this.text().UNSAVED_CHANGES,
				body: this.text().UNSAVED_INFO,
				leftText: this.text().DISCARD,
				rightText: this.text().REVIEW,
				cancelCallback: () => {
					this.twoOptionDialogParams.show = false;
				},
				leftCallback: () => {
					this.cancelCallback.emit();
				},
				rightCallback: () => {
					this.twoOptionDialogParams.show = false;
				}
			};
		} else {
			this.cancelCallback.emit();
		}
	};

	public updateDescription(value: string): void {
		this.level.description = value;
	}

	//-- ERROR MESSAGES --//
	public getLevelNameErrorMessage(): string {
		return this.hasLevelNameError() ? this.text().LEVEL_NAME_TAKEN : "";
	}

	public hasLevelNameError(): boolean {
		return this.levelForm.controls["level"].errors && this.levelForm.controls["level"].value;
	}

	public getGroupAccess(group: PermissionGroup): string {
		if (group.togglePermission && !group.togglePermission.value) return this.text().PERMISSION_ACCESS_NO;
		if (group.togglePermission && group.togglePermission.value && group.childPermissions.length == 0) return this.text().PERMISSION_ACCESS_FULL;
		let checkedCheckbox = 0;
		for (let i = 0; group.childPermissions.length > i; i++) {
			if (group.childPermissions[i]?.value) checkedCheckbox++;
		}
		if (group.childPermissions.length === checkedCheckbox) return this.text().PERMISSION_ACCESS_FULL;
		if (group.togglePermission && group.togglePermission.value && checkedCheckbox == 0) return this.text().PERMISSION_ACCESS_VIEW_ONLY;
		if (!group.togglePermission && checkedCheckbox == 0) return this.text().PERMISSION_ACCESS_NO;
		return this.text().PERMISSION_ACCESS_PARTIAL;
	}

	//-- END ERROR MESSAGES --//
	//--TOGGLE FUNCTIONS --//
	public togglePermissionSwitch: Function = (group: PermissionGroup) => {
		group.togglePermission!.value = !group.togglePermission?.value;
		if (!group.togglePermission?.value) {
			for (let i = 0; group.childPermissions.length > i; i++) {
				group.childPermissions[i]!.value = false;
			}
		}
	};

	public isViewOnly: Function = (group: PermissionGroup) => {
		const viewOnly = () => group.togglePermission?.value;
		return viewOnly;
	};

	//-- END TOGGLE FUNCTIONS --/
	private hasChanged(): boolean {
		return this.levelForm.controls["level"].value !== this.level.name || this.levelForm.controls["description"].value !== this.level.description;
	}

	private getPermissions(): void {
		const createReviewKey = this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT) && this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE) ? this.text().CREATE_REVIEW_ARA : this.text().CREATE_REVIEW;
		const viewAraKey = this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT) && this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE) ? this.text().VIEW_ARA : this.text().VIEW;
		const configureKey = this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT) && this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE) ? this.text().CONFIGURE_ARA : this.text().CONFIGURE;

		this.mappedPermissions.set("all_incident_access", this.text().ACCESS_LIVE_INCIDENTS);
		this.mappedPermissions.set("edit_missions", this.text().CREATE_INCIDENT);

		this.mappedPermissions.set("history_access", this.text().ACCESS_HISTORY);
		this.mappedPermissions.set("delete_missions", this.text().DELETE_INCIDENT);

		if (this.func.isFunctionalityAvailable.get(FUNCTIONALITY.EF)) {
			this.mappedPermissions.set("view_ef", this.text().VIEW_EF);
			this.mappedPermissions.set("edit_ef", this.text().EDIT_EF);
			this.mappedPermissions.set("delete_ef", this.text().DELETE_EF);
		}

		if (this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT) && this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE)) {
			this.mappedPermissions.set("sign_ara", this.text().SIGN_ARA);
		}
		this.mappedPermissions.set("create_review_ara", createReviewKey);
		this.mappedPermissions.set("configure_ara", configureKey);
		this.mappedPermissions.set("view_ara", viewAraKey);

		if (this.func.isFunctionalityAvailable.get(FUNCTIONALITY.DECISION)) {
			this.mappedPermissions.set("create_dl", this.text().CREATE_DL);
			this.mappedPermissions.set("view_dl", this.text().VIEW_DL);
		}

		this.mappedPermissions.set("download_media", this.text().DOWNLOAD_MEDIA);

		this.mappedPermissions.set("edit_types", this.text().IRIS_SETTINGS);
		this.mappedPermissions.set("edit_preplanned", this.text().EDIT_PREPLANNED);

		this.mappedPermissions.set("edit_AsPs", this.text().EDIT_OFV_TOOLS);
		this.mappedPermissions.set("edit_agents", this.text().EDIT);
		this.mappedPermissions.set("delete_agents", this.text().DELETE);
		this.mappedPermissions.set("users_manage", this.text().USER_CONFIGURATION);
	}

	private initializeData() {
		this.originalPermissions.clear();
		this.permissions = [];

		for (const [property, label] of this.mappedPermissions.entries()) {
			const value = this.level[property as keyof Level] as boolean;
			this.originalPermissions.set(property, value);
			this.permissions.push({ value, label, property });
		}

		this.name = this.capitalizeLevelName();
		this.setGroups();
	}

	private setGroups(): void {
		this.groups.push({
			title: this.text().PERMISSION_GROUP_LIVE,
			togglePermission: undefined,
			childPermissions: [this.permissions.find((permission) => permission.property === "all_incident_access"), this.permissions.find((permission) => permission.property === "edit_missions")]
		});
		this.groups.push({
			title: this.text().PERMISSION_GROUP_CLOSED,
			togglePermission: undefined,
			childPermissions: [this.permissions.find((permission) => permission.property === "history_access"), this.permissions.find((permission) => permission.property === "delete_missions")]
		});
		// ARA vs TASK MANAGER
		if (this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT) && this.func.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE)) {
			this.groups.push({
				title: this.text().PERMISSION_GROUP_ARA,
				togglePermission: this.permissions.find((permission) => permission.property === "view_ara"),
				childPermissions: [this.permissions.find((permission) => permission.property === "create_review_ara"), this.permissions.find((permission) => permission.property === "sign_ara"), this.permissions.find((permission) => permission.property === "configure_ara")]
			});
		} else {
			this.groups.push({
				title: this.text().PERMISSION_GROUP_TASK_MANAGER,
				togglePermission: this.permissions.find((permission) => permission.property === "view_ara"),
				childPermissions: [this.permissions.find((permission) => permission.property === "create_review_ara"), this.permissions.find((permission) => permission.property === "configure_ara")]
			});
		}
		// ARA vs TASK MANAGER -END
		if (this.func.isFunctionalityAvailable.get(FUNCTIONALITY.DECISION)) {
			this.groups.push({
				title: this.text().DECISION_LOG,
				togglePermission: this.permissions.find((permission) => permission.property === "view_dl"),
				childPermissions: [this.permissions.find((permission) => permission.property === "create_dl")]
			});
		}

		if (this.func.isFunctionalityAvailable.get(FUNCTIONALITY.EF)) {
			this.groups.push({
				title: this.text().EVACUATION_FORM_SHORT,
				togglePermission: this.permissions.find((permission) => permission.property === "view_ef"),
				childPermissions: [this.permissions.find((permission) => permission.property === "edit_ef"), this.permissions.find((permission) => permission.property === "delete_ef")]
			});
		}

		this.groups.push({
			title: this.text().PERMISSION_GROUP_MEDIA,
			togglePermission: this.permissions.find((permission) => permission.property === "download_media"),
			childPermissions: []
		});
		this.groups.push({
			title: this.text().PERMISSION_GROUP_OFV_TOOLS,
			togglePermission: undefined,
			childPermissions: [this.permissions.find((permission) => permission.property === "edit_AsPs"), this.permissions.find((permission) => permission.property === "edit_preplanned")]
		});
		this.groups.push({
			title: this.text().PERMISSION_GROUP_RESOURCES_CONFIG,
			togglePermission: undefined,
			childPermissions: [this.permissions.find((permission) => permission.property === "edit_agents"), this.permissions.find((permission) => permission.property === "delete_agents")]
		});
		this.groups.push({
			title: this.text().PERMISSION_GROUP_USER_CONFIG,
			togglePermission: this.permissions.find((permission) => permission.property === "users_manage"),
			childPermissions: []
		});
		this.groups.push({
			title: this.text().PERMISSION_GROUP_IRIS_SETTINGS,
			togglePermission: this.permissions.find((permission) => permission.property === "edit_types"),
			childPermissions: []
		});
	}

	private capitalizeLevelName(): string {
		return `${this.level.name.charAt(0).toLocaleUpperCase()}${this.level.name.slice(1)}`;
	}
}

interface Permission {
	value: boolean;
	label: string;
	property: string;
}

interface PermissionGroup {
	title: string;
	togglePermission: Permission | undefined;
	childPermissions: Array<Permission | undefined>;
}

enum STATE {
	ON = "on",
	OFF = "off"
}

import { Component, EventEmitter, Output, Input, OnInit } from "@angular/core";
import { AreaType } from "src/app/dto/items/types/area-type";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { INCIDENT_TOOL } from "src/app/global/constants/enums/incident_tools";
import { FunctionalityService } from "src/app/global/functionality.service";
import { FUNCTIONALITY } from "src/app/global/functionality.service";
import { UserService } from "src/app/settings/user/user.service";
@Component({
	selector: "app-map-tools",
	templateUrl: "map-tools.component.html",
	styleUrls: ["map-tools.css"]
})
export class MapToolsComponent implements OnInit {
	@Input() isFuncAvailable!: Map<FUNCTIONALITY, boolean>;
	@Output() setFsg = new EventEmitter<void>();
	@Output() cancelDrawing = new EventEmitter<void>();
	@Output() setScreen = new EventEmitter<SCREEN>();
	@Output() drawPoi = new EventEmitter<PoiType>();
	@Output() drawPolygon = new EventEmitter<AreaType>();
	@Output() drawCircle = new EventEmitter<AreaType>();
	@Output() unselectEvent = new EventEmitter<void>();
	@Output() activeMapItem = new EventEmitter<TOOLS.POI | TOOLS.AREA>();
	@Output() openMenu = new EventEmitter<void>();

	public activeTool: TOOLS = TOOLS.NONE;
	public tools = TOOLS;
	public screens = SCREEN;
	public incidentTools = INCIDENT_TOOL;
	public eF: boolean = true;
	public tD: boolean = true;

	public readonly text: () => LocaleMap;

	private readonly user: UserService;

	constructor(textProv: TextProvider, user: UserService) {
		this.text = textProv.getStringMap;
		this.user = user;
	}
	ngOnInit(): void {
		this.getFunctionalities();
	}
	public readonly selectPoi: Function = () => {
		const previousValue = this.activeTool;
		this.unselectTools();
		if (previousValue !== TOOLS.POI) {
			this.activeTool = TOOLS.POI;
			this.activeMapItem.emit(TOOLS.POI);
			this.openMenu.emit();
		}
	};

	public readonly selectArea: Function = () => {
		const previousValue = this.activeTool;
		this.unselectTools();
		if (previousValue !== TOOLS.AREA) {
			this.activeTool = TOOLS.AREA;
			this.activeMapItem.emit(TOOLS.AREA);
			this.openMenu.emit();
		}
	};

	public readonly selectEvacuation: Function = () => {
		const previousValue = this.activeTool;
		this.unselectTools();
		if (previousValue !== TOOLS.FSG) {
			this.activeTool = TOOLS.FSG;
			return this.setFsg.emit();
		}
	};

	public readonly selectOverlay: Function = () => {
		const previousValue = this.activeTool;
		this.unselectTools();
		if (previousValue !== TOOLS.OVERLAY) {
			this.activeTool = TOOLS.OVERLAY;
		}
		this.setScreen.emit(this.screens.OVERLAY);
	};

	public readonly selectTactical: Function = (): void => {
		const previousValue = this.activeTool;
		this.unselectTools();
		if (previousValue !== TOOLS.TACTICAL) {
			this.activeTool = TOOLS.TACTICAL;
		}
		return this.setScreen.emit(this.screens.TACTICAL);
	};

	public readonly unselectTools: Function = () => {
		this.activeTool = TOOLS.NONE;
		this.unselectEvent.emit();
	};

	public openMenuItem(event: Event, menuFn: Function): void {
		menuFn();
		event.stopPropagation();
	}
	public getFunctionalities(): void {
		this.eF = this.isFuncAvailable.get(FUNCTIONALITY.EF)!;
		this.tD = this.isFuncAvailable.get(FUNCTIONALITY.TACTICALD)!;
	}

	public EFEditPermission(): boolean {
		return this.user.getCurrentUserLevel()?.edit_ef;
	}
}

export enum TOOLS {
	NONE,
	UNDO,
	VISIBILITY,
	AREA,
	FSG,
	POI,
	RULER,
	BOUNDS,
	TACTICAL,
	OVERLAY
}

<div class="main-black-mask" (click)="closeCb(); $event.stopPropagation()"></div>
<div class="edit-modal hardware-modal"
data-cy="assign-camera-modal">
	<div class="title">
		{{ text().CAMERAS.toUpperCase() }}
	</div>
	<app-white-cross class="dialog-cross-button cross-button" (clickCb)="closeCb();$event.stopPropagation();"></app-white-cross>
	<div class="filters-container">
		<div class="name-filter">
			<input
				placeholder="{{ text().NAME }}"
				class="blue-plain-input"
				[(ngModel)]="nameQuery"
				(ngModelChange)="applyFilters()"
			/>
		</div>
		<div class="station-filter">
			<app-blue-dropdown
				class="dropdown"
				[placeholderText]="text().STATION"
				[object]="stationQuery"
				[options]="stations"
				[getObjectText]="stationDropdownGetMainText"
				[getOptionText]="stationDropdownGetOptionText"
				[compareSelect]="stationDropdownCompareSelect"
				(itemClickCallback)="stationDropdownChangeCallback($event)"
			>
			</app-blue-dropdown>
		</div>
		<div class="status-filter">
			<app-blue-dropdown
				class="dropdown"
				[placeholderText]="text().STATUS"
				[object]="statusQuery"
				[options]="statuses"
				[getObjectText]="statusDropdownGetMainText"
				[getOptionText]="statusDropdownGetOptionText"
				[compareSelect]="statusDropdownCompareSelect"
				(itemClickCallback)="statusDropdownChangeCallback($event)"
			></app-blue-dropdown>
		</div>
	</div>
	<div class="camera-list-container">
		<div *ngFor="let hw of hardware">
			<div
				class="camera-container {{
					isHardwareSelectedByCurrentResource(hw) ? 'selected' : ''
				}}"
				[attr.data-cy]="'select-camera-' + hw.name"
				(click)="assignCb(hw, resource); closeCb()"
			>
				<div class="img-container"><img src="resources/img/icons-hw/cam.png" /></div>
				<div class="name-container">{{ hw.name }}</div>
				<div class="station-container">{{ hw.station }}</div>
				<div class="status-container">
					<div
						class="{{
							isHardwareSelectedByAnyResource(hw) ? 'unavailable' : 'available'
						}}"
					></div>
				</div>
			</div>
			<!-- TO-DO -->
			<div *ngIf="!nameFilterFunc(hw) && (!hw || !hardware.length)" class="no-results">
				No results
			</div>
		</div>
	</div>
</div>

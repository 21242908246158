import { Injectable } from "@angular/core";
import { WebRequestFactory } from "../../http/web.request.factory";
import { UserService } from "../../settings/user/user.service";
import { Camera } from "../../dto/resources/camera";
import { Resource } from "../../dto/resources/resource";
import { User } from "../../dto/user/user";
import { VideoFeedSlot, VIDEO_FEED_STATUS } from "../../dto/ui/video-slot";
import { ConstantsService } from "../../global/constants/constants.service";
import { MessagingService } from "../../global/messaging/messaging.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { AutomationService } from "src/app/global/automation/automation.service";
import { Personnel } from "src/app/dto/resources/personnel";
import * as kurentoUtils from "kurento-utils";
import { Subject } from "rxjs";
import { LoginService } from "src/app/login/login.service";
import { BufferProvider } from "src/app/global/automation/buffer/buffer.provider";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { LOG_TYPE } from "src/app/global/constants/enums/log_types";
import { URLMap } from "src/app/global/constants/enums/url-map";
import axios from "axios";
import { StreamURIs } from "src/app/dto/replay/stream-vods";
import { Incident } from "src/app/dto/items/incident";

@Injectable({
	providedIn: "root"
})
export class VideoService {
	public cameras: Array<Camera> = [];
	public videoFeedSlots: Array<VideoFeedSlot> = [];
	public readonly addResource$ = new Subject<Personnel>();

	private readonly getVodData = new Map<string, StreamData>();
	private user: User;
	private slotsPerRow: number = 2;
	private webRtcPeers: { [name: string]: kurentoUtils.WebRtcPeer } = {};
	private kClients: { [name: string]: any } = {};
	private ws: { [name: string]: WebSocket } = {};
	private last_ts = 0;
	private time_stopped = false;
	private readonly text: () => LocaleMap;

	private readonly socketAttemptIntervalId = new Map<string, number>();
	private readonly socketReconnectIntervalId = new Map<string, number>();

	private readonly SOCKET_TIMEOUT_REATTEMPT_MS = 3000;
	private BACKEND_URL: string = "";

	constructor(private wreq: WebRequestFactory, private mssg: MessagingService, private userServ: UserService, private cnst: ConstantsService, private auto: AutomationService, private login: LoginService, private buffer: BufferProvider, private textP: TextProvider) {
		this.user = this.login.user;
		this.text = this.textP.getStringMap;
		this.checkNumSlots(this.slotsPerRow);
		this.BACKEND_URL = "https://dev.irisunblur.com"; //URLMap.WSURL();

		this.loadKurentoLib();

		this.mssg.registerListener(MESSAGE_TYPE.TIMESTAMP_CHANGE, (vars: any) => this.updateVodPositions(vars.ts, vars.isSkip), false, false);
		this.mssg.registerListener(MESSAGE_TYPE.UNLOAD_MISSION, this.clearTimeouts);
		this.mssg.registerListener(
			MESSAGE_TYPE.PLAY_PAUSE_CHANGE,
			(isPlaying: boolean) => {
				this.playPauseVodPositions(isPlaying);
			},
			false,
			false
		);
	}

	public readonly getSlotsPerRow: Function = () => {
		return this.slotsPerRow;
	};

	public readonly removeVideoFeed: Function = (cameraNameOrUrl: string) => {
		if (!cameraNameOrUrl || cameraNameOrUrl == "") return;
		if (cameraNameOrUrl.startsWith("rtmp://")) cameraNameOrUrl = cameraNameOrUrl.split("?")[0];
		for (var i = 0; i < this.videoFeedSlots.length; i++) {
			if (this.videoFeedSlots[i].camera && (this.videoFeedSlots[i].camera?.milestone_name == cameraNameOrUrl || this.videoFeedSlots[i].camera?.rtmp_url == cameraNameOrUrl)) this.videoFeedSlots[i] = new VideoFeedSlot();
		}
		// this.checkNumSlots(this.slotsPerRow);
	};
	/**
	 *
	 * @param videoFeedSlots
	 * @returns {Function}
	 * @memberof VideoService
	 * @description Updates the video feed slots with the new video feed slots from video component
	 */
	public updateVideoFeed: Function = (videoFeedSlots: Array<VideoFeedSlot>) => {
		this.videoFeedSlots = videoFeedSlots;
	};

	public readonly addVideoFeedOnAvailable: (agent: Personnel) => void = (agent) => {
		this.addResource$.next(agent);
	};

	public readonly addVideoFeed: Function = (agent: Personnel, index: number, elem: HTMLElement) => {
		if (agent.id_camera > 0) {
			// stream from the Camera object attached to the agent
			this.getAgentCamera(agent).then((cam: Camera) => this.addCameraToVideoFeed(cam, index, elem));
		} else if (this.userServ.isResourceWithUser(agent)) {
			// stream websocket created from agent.id
			//MONDAY
			var cam = { ims_name: "resourcefeed-" + agent.id };
			this.addCameraToVideoFeed(cam as Camera, index, elem);
		}
	};

	public readonly setSlotStreamStatus: Function = (name: string, status: number) => {
		var slot = this.videoFeedSlots.find((e) => e.imsPeer === name);
		if (slot) slot.status = status;
	};

	public readonly setSlotMessage: Function = (name: string, message: string) => {
		var slot = this.videoFeedSlots.find((e) => e.imsPeer === name);
		if (slot) slot.message = message;
	};

	// gets the VOD served and creates a PlayerEndpoint -> WebRtcEndpoint to stream it
	public readonly onOfferVod: Function = async (err: any, sdp: string, data: any, name: string, idx: number) => {
		if (err) return this.onErrorIms(err, name);
		const contr = this;
		var uri = data.filename;
		// @ts-ignore
		kurentoClient(data.kms_server, { clientId: data.client_id }, (err, client) => {
			if (err) return this.onErrorIms(err, name);
			this.kClients[name] = client;
			client.create("MediaPipeline", (err: any, pipeline: any) => {
				if (err) return this.onErrorIms(err, name);
				pipeline.create("WebRtcEndpoint", (err: any, webRtc: any) => {
					if (err) return this.onErrorIms(err, name);
					this.setIceCandidateCallbacks(this.webRtcPeers[name], webRtc);
					pipeline.create("PlayerEndpoint", { useEncodedMedia: true, uri: uri }, (err: any, player: any) => {
						if (err) return this.onErrorIms(err, name);
						player.on("EndOfStream", () => {
							this.stopImsStream(name);
						});
						player.connect(webRtc);
						webRtc.processOffer(sdp, (err: any, sdpAnswer: any) => {
							if (err) return this.onErrorIms(err, name);
							webRtc.gatherCandidates((err: any) => {
								if (err) console.error(err);
							});
							this.webRtcPeers[name].processAnswer(sdpAnswer);
							let _data = contr.getVodData.get(name);
							let streamData = _data ? _data : new StreamData();
							streamData.player = player;
							contr.getVodData.set(name, streamData);
							player.play();
							this.videoFeedSlots[idx].status = 2;
							this.setSlotMessage(name, null);
							webRtc.on("MediaFlowInStateChanged", () => {
								player.setPosition(data.offset_ms);
								if (this.time_stopped) player.pause();
							});
						});
					});
				});
			});
		});
	};

	public readonly onErrorIms: Function = (error: any, name: string) => {
		console.error("error on creating vod stream for " + name);
		console.error(error);
		this.stopImsStream(name);
		return false;
	};

	// function to setup ICE candidates for a webrtcendpoint/peer combination, hence only used when streaming vods
	public readonly setIceCandidateCallbacks: Function = (webRtcPeer: any, webRtcEp: any, onerror: any) => {
		webRtcPeer.on("icecandidate", function (candidate: any) {
			// @ts-ignore
			candidate = kurentoClient.getComplexType("IceCandidate")(candidate);

			webRtcEp.addIceCandidate(candidate, onerror);
		});

		webRtcEp.on("IceCandidateFound", function (event: any) {
			var candidate = event.candidate;
			webRtcPeer.addIceCandidate(candidate, onerror);
		});
	};

	public readonly onOfferViewer: Function = (error: any, offerSdp: string, name: string) => {
		if (error) {
			console.error(error);
			return;
		}
		var message = {
			id: "sdp_offer",
			token: this.wreq.getAuthHeader(),
			domain: this.BACKEND_URL,
			name: this.user.name,
			sdpOffer: offerSdp
		};
		this.sendSocketMessage(this.ws[name], JSON.stringify(message));
	};

	public readonly sdpServerReception: Function = (message: any, name: string) => {
		if (message.response != "accepted") {
			var errorMsg = message.message ? message.message : "Unknown error";
			console.warn("video ws " + name + " sdp negotiation not accepted for the following reason: " + errorMsg);
			this.stopImsStream(name);
		} else {
			this.webRtcPeers[name].processAnswer(message.sdpAnswer);
		}
	};

	public readonly stopImsStream: (name: string, isTimeout?: boolean, isReconnect?: boolean) => void = (name, isTimeout, isReconnect) => {
		if (this.ws[name]) {
			this.wreq.logInformation(LOG_TYPE.VIDEO_CLOSE, name);
			var message = {
				id: "stop",
				name: this.user.name,
				domain: this.BACKEND_URL
			};
			if (this.ws[name].readyState === WebSocket.OPEN) this.ws[name].send(JSON.stringify(message));
			this.ws[name].close();
			delete this.ws[name];
		}
		let data = this.getVodData.get(name);
		if (data) {
			if (data.player) data.player.stop();
			if (this.webRtcPeers[name]) {
				this.webRtcPeers[name].dispose();
				delete this.webRtcPeers[name];
			}
			clearInterval(data.pauseInterval);
			clearInterval(data.resetTimeout);
		}
		if (this.kClients[name]) {
			this.kClients[name].close();
			delete this.kClients[name];
		}

		if (this.webRtcPeers[name]) {
			delete this.webRtcPeers[name];
		}
		const slot = this.videoFeedSlots.find((e) => e.imsPeer === name);
		if (slot) {
			slot.status = isTimeout ? VIDEO_FEED_STATUS.NOT_FOUND : isReconnect ? VIDEO_FEED_STATUS.OPENING : VIDEO_FEED_STATUS.CLOSED;
		}
	};

	public readonly checkNumSlots: Function = (spr: number) => {
		this.slotsPerRow = spr;
		while (this.videoFeedSlots.length < this.slotsPerRow * 2) {
			this.videoFeedSlots.push(new VideoFeedSlot());
		}
		var lastunactive = 0;
		var activeSlots = 0;
		for (var n = 0; n < this.videoFeedSlots.length; n++) {
			if (this.videoFeedSlots[this.videoFeedSlots.length - 1 - n].isActive()) activeSlots++;
			else if (activeSlots == 0) lastunactive++;
		}
		while (this.videoFeedSlots.length > this.slotsPerRow * 2 && lastunactive >= this.slotsPerRow - 1 && activeSlots + this.slotsPerRow < this.videoFeedSlots.length) {
			if (this.videoFeedSlots[this.videoFeedSlots.length - 1].isActive()) break;
			this.videoFeedSlots.pop();
			lastunactive--;
		}
		if (activeSlots + this.slotsPerRow > this.videoFeedSlots.length) {
			for (var l = 0; l < this.slotsPerRow; l++) {
				this.videoFeedSlots.push(new VideoFeedSlot());
			}
		}
	};

	public readonly updateVodPositions: Function = (new_timestamp: number, jump: boolean) => {
		if (jump && this.time_stopped) {
			// jumps resume playing by default
			this.playPauseVodPositions(true);
		}
		if (new_timestamp - this.last_ts > 2000 || this.last_ts > new_timestamp) {
			// only manually move the videos if speed is not natural
			// ideally that should mean a threshold of a second (1000)
			// but due to inaccuracies the threshold of natural speed can be higher, hence 2000
			for (const name in this.webRtcPeers) {
				if (this.webRtcPeers.hasOwnProperty(name)) {
					var peer = this.webRtcPeers[name];
					let data = this.getVodData.get(name)!;

					if (data && data.currentVod) {
						var new_offset = new_timestamp - (data.originTs ? data.originTs : 0); //if originTs is undefined
						data.currentTs = new_offset;
						// This might be called before the stream/vod setup is complete and the reference for player endpoint is set
						if (data.player) {
							data.player.setPosition(new_offset);
						}
					}
				}
			}
		}
		this.last_ts = new_timestamp;
	};

	public readonly playPauseVodPositions: Function = (shouldStartPlaying: boolean) => {
		this.videoFeedSlots.forEach((slot) => (slot.paused = !shouldStartPlaying));
		for (const name in this.webRtcPeers) {
			if (this.webRtcPeers.hasOwnProperty(name)) {
				const contr = this;
				let data = this.getVodData.get(name);
				if (data && data.currentVod) {
					if (shouldStartPlaying) {
						data.player!.play();
						data.player!.setPosition(data.currentTs);
						clearInterval(data.pauseInterval);
					} else {
						data.player!.pause();
						(data.player as any).getPosition((err: any, pos: any) => {
							//TO-DO
							data!.currentTs = pos;
							contr.getVodData.set(name, data!);
						});
					}
				}
			}
		}
		this.time_stopped = !shouldStartPlaying;
	};

	private agentHasCam(camera: Camera, agent: Resource): boolean {
		if (camera.ims_name.match(/resourcefeed/)) {
			return agent.id === Number.parseInt(camera.ims_name.substring(camera.ims_name.length - 3, camera.ims_name.length));
		} else return agent.__camera === camera;
	}

	private addCameraToVideoFeed(camera: Camera, index: number, elem: HTMLElement): void {
		for (var i = 0; i < this.videoFeedSlots.length; i++) {
			if (i !== index && this.videoFeedSlots[i].agent && this.agentHasCam(camera, this.videoFeedSlots[i].agent!)) {
				this.videoFeedSlots[i].empty();
				this.videoFeedSlots[i].status = VIDEO_FEED_STATUS.OPENING;
			}
		}
		if (camera.ims_name) {
			if (this.auto.liveMode) this.setupImsLiveStream(camera, index, elem);
			else this.setupImsVodStream(camera, index, this.buffer.timestamp, null, elem);
		}
		this.videoFeedSlots[index].status = VIDEO_FEED_STATUS.OPENING;
	}

	private readonly setupImsLiveStream: Function = (camera: Camera, idx: number, vid_dom_elem: HTMLElement) => {
		var sock_endpoint = this.cnst.BACKEND_HOST + "/" + camera.ims_name;
		const url = URLMap.IMSURL();
		var _ws = new WebSocket("wss://" + url.host + ":" + url.port + "/" + sock_endpoint);
		this.wreq.logInformation(LOG_TYPE.VIDEO_OPEN, camera.ims_name);
		var receivedReply = false;
		this.ws[camera.ims_name] = _ws;
		if (!this.webRtcPeers[camera.ims_name]) {
			var video = vid_dom_elem;
			const constraints = {
				audio: false
			};

			var options = {
				remoteVideo: video,
				onicecandidate: (candidate: any) => {
					this.onIceCandidate(candidate, camera.ims_name);
				},
				mediaConstraints: constraints
			};

			const contr = this;
			this.webRtcPeers[camera.ims_name] = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (error: any) {
				if (error) {
					console.error(error);
					return;
				}
				// @ts-ignore
				this.generateOffer((err, offer) => {
					let finalOffer = contr.setSdpToPreferH264(offer);
					contr.onOfferViewer(err, finalOffer, camera.ims_name);
				});
			});
			_ws.onmessage = (message) => {
				var parsedMessage = JSON.parse(message.data);
				receivedReply = true;
				switch (parsedMessage.id) {
					case "viewerResponse":
						this.sdpServerReception(parsedMessage, camera.ims_name);
						break;
					case "iceCandidate":
						this.webRtcPeers[camera.ims_name].addIceCandidate(parsedMessage.candidate);
						break;
					case "disconnect":
						this.setSlotMessage(camera.ims_name, this.text().STREAM_CONNECTION_LOST);
						break;
					case "reconnect":
						this.setSlotMessage(camera.ims_name, null);
						this.stopImsStream(camera.ims_name, false, true);
						this.addCameraToVideoFeed(camera, idx, vid_dom_elem);
						let data = this.getVodData.get(camera.ims_name);
						clearTimeout(data!.deadTimer);
						break;
					case "close":
						let datac = this.getVodData.get(camera.ims_name);
						if (!datac) {
							datac = new StreamData();
							this.getVodData.set(camera.ims_name, datac);
						}
						if (!datac.alreadyClosed) {
							datac!.alreadyClosed = true;
							datac!.resetTimeout = window.setTimeout(() => {
								datac!.alreadyClosed = false;
								this.stopImsStream(camera.ims_name, false, true);
								this.addCameraToVideoFeed(camera, idx, vid_dom_elem);
							}, this.SOCKET_TIMEOUT_REATTEMPT_MS);
						}
						break;
					//}

					case "status":
						switch (parsedMessage.value) {
							case "OPENING":
								this.videoFeedSlots[idx].status = VIDEO_FEED_STATUS.OPENING;
								this.setSlotMessage(camera.ims_name, "Reconnecting");
								break;
							case "STREAMING":
								this.videoFeedSlots[idx].status = VIDEO_FEED_STATUS.STREAMING;
								this.setSlotMessage(camera.ims_name, null);
								break;
							case "LISTENING":
								this.setSlotMessage(camera.ims_name, null);
								break;
							default:
								this.setSlotMessage(camera.ims_name, null);
								break;
						}
						break;
					case "reset":
						this.setSlotStreamStatus(camera.ims_name, "RECONNECTING");
						this.setSlotMessage(camera.ims_name, "Server failure. Reconnecting");
						//if (data) {
						data!.resetTimeout = window.setTimeout(
							() => {
								this.stopImsStream(camera.ims_name);
								this.addCameraToVideoFeed(camera, idx, vid_dom_elem);
							},
							parsedMessage.time_ms && parsedMessage.time_ms * 3 > 15000 ? parsedMessage.time_ms * 3 : 15000
						);
						break;
					//}

					default:
						console.error("websocket cam: unrecog msg ", parsedMessage);
				}
			};
		}
		const oldT = this.socketReconnectIntervalId.get(camera.ims_name);
		if (Number.isSafeInteger(oldT)) clearTimeout(oldT);
		const connectionTimeout = window.setTimeout(() => {
			if (!receivedReply) {
				const slot = this.videoFeedSlots.find((e) => e.imsPeer === camera.ims_name);
				this.stopImsStream(camera.ims_name, false, true);
				if (slot) this.addCameraToVideoFeed(camera, idx, vid_dom_elem);
			}
		}, this.SOCKET_TIMEOUT_REATTEMPT_MS);
		this.socketReconnectIntervalId.set(camera.ims_name, connectionTimeout);
	};

	//m=video 9 UDP/TLS/RTP/SAVPF 96 97 98 99 100 101 122 102 121 127 120 125 107 108 109 35 36 124 119 123 118 114 115 116 37\r\n
	private readonly setSdpToPreferH264: Function = (offer: string) => {
		let H264CodecIds = new Array<string>();
		let editedVideoLine = "";
		let lines = offer.split("\r\n");
		let videoLineIdx = lines.findIndex((e) => e.match(/m=video/i));
		editedVideoLine = lines[videoLineIdx].split(" ").slice(0, 3).join(" ");
		let supportedCodecIds = lines[videoLineIdx].split(" ").slice(3, lines[videoLineIdx].split(" ").length);
		let outputCodecIds = new Array<string>();
		lines.forEach((line) => {
			if (line.match(/rtpmap/i) && line.match(/h264/i)) {
				H264CodecIds[H264CodecIds.length] = line.split("rtpmap:")[1].split("H264")[0].trim();
			}
		});
		H264CodecIds.forEach((h264codec) => {
			outputCodecIds[outputCodecIds.length] = h264codec;
		});
		supportedCodecIds.forEach((codec) => {
			if (!H264CodecIds.find((e) => e === codec)) outputCodecIds[outputCodecIds.length] = codec;
		});
		editedVideoLine += " " + outputCodecIds.join(" ");
		lines.splice(videoLineIdx, 1, editedVideoLine);
		return lines.join("\r\n");
	};

	// since the ICE negotiation and everything will be done client-side all the websocket does is authenticate, send VOD filename and calculate the ms offset
	private readonly setupImsVodStream: Function = (camera: Camera, idx: number, time_ms: number, ims_name: string, vid_dom_elem: HTMLElement) => {
		if (!ims_name) ims_name = camera.ims_name;
		const url = URLMap.IMSURL();
		const sock_endpoint = "wss://" + url.host + ":" + url.port + "/vod";
		let receivedReply = false;
		const _ws = new WebSocket(sock_endpoint);
		this.ws[camera.ims_name] = _ws;

		const message = {
			id: "get_filename",
			key: ims_name,
			timestamp: time_ms,
			name: this.user.name,
			domain: this.BACKEND_URL,
			token: this.wreq.getAuthHeader()
		};
		this.sendSocketMessage(_ws, JSON.stringify(message));
		setTimeout(() => {
			if (!receivedReply) this.stopImsStream(camera.ims_name, true);
		}, 10000);

		_ws.onmessage = (message) => {
			var parsedMessage = JSON.parse(message.data);
			receivedReply = true;
			const contr = this; //THIS
			switch (parsedMessage.id) {
				case "vodFilename":
					for (const name in this.webRtcPeers) {
						const data = this.getVodData.get(name);
						if (data) {
							clearInterval(data.pauseInterval);
							if (data.currentVod === parsedMessage.filename) {
								(data.player as any).setPosition(parsedMessage.offset_ms);
								return;
							} else {
								delete this.webRtcPeers[ims_name];
							}
						}
					}
					const options = {
						remoteVideo: vid_dom_elem,
						onicecandidate: (candidate: any) => {
							this.onIceCandidate(candidate, ims_name);
						}
					};
					this.webRtcPeers[ims_name] = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (error: any) {
						if (error) return contr.onErrorIms(error);
						const data = new StreamData();
						data.currentVod = parsedMessage.filename;
						data.currentTs = parsedMessage.offset_ms;
						data.originTs = parsedMessage.origin_ms;
						data.slotIdx = idx;
						contr.getVodData.set(ims_name, data);
						// @ts-ignore
						this.generateOffer((err, sdp) => {
							const finalOffer = contr.setSdpToPreferH264(sdp);
							contr.onOfferVod(err, finalOffer, parsedMessage, ims_name, idx);
						});
					});
					break;
				case "error":
					console.error(parsedMessage.desc);
					this.stopImsStream(camera.ims_name, true);
					break;
			}
		};
	};

	public async getIncidentVodList(id_mission: number, time_ms: number): Promise<Array<StreamURIs>> {
		const url = URLMap.IMSURL();
		var sock_endpoint = "wss://" + url.host + ":" + url.port + "/vod";
		var receivedReply = false;
		return new Promise((resolve, reject) => {
			var _ws = new WebSocket(sock_endpoint);
			_ws.onmessage = (message) => {
				var parsedMessage = JSON.parse(message.data);
				receivedReply = true;
				switch (parsedMessage.id) {
					case "vodArray":
						return resolve(
							parsedMessage.vods.map((e: any) => {
								return e as StreamURIs;
							})
						);
						break;
					default:
						throw "Unknown server response " + JSON.stringify(parsedMessage);
				}
			};
			var message = {
				id: "get_incident_vods",
				incident: id_mission,
				start_time: time_ms,
				domain: this.BACKEND_URL, // URLMap.WSURL(),
				token: this.wreq.getAuthHeader()
			};
			this.sendSocketMessage(_ws, JSON.stringify(message));
			setTimeout(() => {
				if (!receivedReply) return reject("Server timeout");
			}, 3000);
		});
	}

	public async downloadVOD(fname: string): Promise<Blob | undefined> {
		const url = URLMap.IMSURL();
		var sock_endpoint = "https://" + url.host + ":" + url.port + "/fetch/vod";
		const ans = await axios.get(sock_endpoint, {
			headers: {
				Authorization: this.wreq.getAuthHeader(),
				Filename: fname,
				Domain: this.BACKEND_URL
			},
			responseType: "blob"
		});
		return ans.data;
	}

	public async downloadIncidentVODs(event: Incident): Promise<Blob> {
		const url = URLMap.IMSURL();
		var sock_endpoint = "https://" + url.host + ":" + url.port + "/fetch/incident";
		const ans = await axios.get(sock_endpoint, {
			headers: {
				Authorization: this.wreq.getAuthHeader(),
				Domain: this.BACKEND_URL
			},
			params: {
				id_incident: event.id,
				number_incident: event.num,
				start_time: event.start_time_ms
			},
			responseType: "blob"
		});
		return ans.data;
	}

	private readonly onIceCandidate: Function = (candidate: RTCIceCandidate, name: string) => {
		//candidate.candidate = candidate.candidate.replace('192.168.48.238',client_ip);
		var message = {
			id: "ice_candidate",
			candidate: candidate
		};
		this.sendSocketMessage(this.ws[name], JSON.stringify(message));
	};

	private readonly getAgentCamera: Function = async (agent: Resource) => {
		if (agent.__camera) return Promise.resolve(agent.__camera);
		const cam = agent.__camera;
		if (cam) return Promise.resolve(cam);
		const cameraJson = await this.wreq.getCameraById(agent.id_camera);
		if (cameraJson) {
			const camera = Camera.fromJson(cameraJson);
			if (!this.cameras.find((e) => e.id === camera.id)) this.cameras.push(camera);
			return camera;
		} else return undefined;
	};

	private readonly sendSocketMessage: (socket: WebSocket, message: string) => void = (socket, message) => {
		switch (socket.readyState) {
			case WebSocket.CONNECTING:
				setTimeout(() => this.sendSocketMessage(socket, message), 1000);
				break;
			case WebSocket.OPEN:
				socket.send(message);
				break;
			default:
				break;
		}
	};

	private readonly loadKurentoLib = (): void => {
		const scriptElt = document.createElement("script");
		scriptElt.setAttribute("type", "text/javascript");
		scriptElt.setAttribute("src", "resources/js/kurento-client/js/kurento-client.min.js");
		document.body.appendChild(scriptElt);
	};

	private readonly clearTimeouts = (): void => {
		const arr = Array.from(this.socketAttemptIntervalId);
		arr.forEach((e) => {
			clearTimeout(e[1]);
		});
		const recArr = Array.from(this.socketReconnectIntervalId);
		recArr.forEach((e) => {
			clearTimeout(e[1]);
		});
	};
}
export class StreamData {
	//STREAMDATA
	currentVod: string | undefined;
	currentTs: number | undefined;
	originTs: number | undefined;
	slotIdx: number | undefined;
	player: any | undefined;
	pauseInterval: number | undefined;
	connectionTimeout: number | undefined;
	alreadyClosed: boolean | undefined;
	resetTimeout: number | undefined;
	deadTimer: number | undefined;
	offset_ms: number | undefined;

	constructor() {}
}

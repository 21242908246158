import { Component, Input } from "@angular/core";
import { NavTabItem } from "src/app/dto/ui/tab";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { NavigationService } from "src/app/navigation/navigation.service";

@Component({
	selector: "app-nav-tab",
	templateUrl: "nav-tab.component.html",
	styleUrls: ["nav-tab.css"]
})
export class NavTabComponent {
	@Input() tabs: Array<NavTabItem> = [];
	@Input() isInCommandSCreen: boolean = false;
	@Input() getActiveTab: Function = () => {
		return 0;
	};

	private readonly nav: NavigationService;

	constructor(nav: NavigationService) {
		this.nav = nav;
	}

	get isCommandStructure(): boolean {
		return this.nav.currentScreen === SCREEN.COMMAND_STRUCTURE;
	}

	public onClickOption = (tab: NavTabItem): void => tab.callback(tab);
	public onCancel = (): void => this.nav.goBottom();
}

<div class="map-tools-container">
	<div class="map-tools">
		<div class="item-list">
			<!-- POI -->
			<button
				data-add-action-name="add-new-poi"
				class="tool-button {{ activeTool === tools.POI ? 'active selected' : '' }}"
				(click)="openMenuItem($event, selectPoi)"
			>
				<div class="hover-layer"></div>
				<img src="resources/img/map-tools/poi-icon.svg" class="icon" />
				<span>POI</span>
			</button>

			<!-- AREA -->
			<button
				data-add-action-name="add-new-area"
				class="tool-button {{ activeTool === tools.AREA ? 'active selected' : '' }}"
				(click)="openMenuItem($event, selectArea)"
			>
				<div class="hover-layer"></div>
				<img src="resources/img/map-tools/area-icon.svg" class="icon" />
				<span>{{ text().AREA }}</span>
			</button>

			<!-- EVF -->
			<button
				*ngIf="eF && this.EFEditPermission()"
				data-add-action-name="add-new-ef"
				class="tool-button {{ activeTool === tools.FSG ? 'active selected' : '' }}"
				(click)="openMenuItem($event, selectEvacuation)"
			>
				<div class="hover-layer"></div>
				<img src="resources/img/map-tools/fsg-icon.svg" class="icon" />

				<span>{{ text().EVACUATION }}</span>
			</button>

			<!-- OVERLAY -->
			<button
				data-add-action-name="add-new-overlay"
				class="tool-button {{ activeTool === tools.OVERLAY ? 'active selected' : '' }}"
				(click)="openMenuItem($event, selectOverlay)"
			>
				<div class="hover-layer"></div>
				<img src="resources/img/map-tools/overlay-icon.svg" class="icon" />
				<span>{{ text().OVERLAY }}</span>
			</button>

			<!-- TD -->
			<button
				*ngIf="tD"
				data-add-action-name="add-new-td"
				class="tool-button {{ activeTool === tools.TACTICAL ? 'active selected' : '' }}"
				(click)="openMenuItem($event, selectTactical)"
			>
				<div class="hover-layer"></div>
				<img src="resources/img/map-tools/td-icon.svg" class="icon" />
				<span>{{ text().TACTICAL }}</span>
			</button>
		</div>
	</div>
</div>

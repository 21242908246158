import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { DTOArray } from "src/app/dto/net/dto-array";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { Appliance } from "src/app/dto/resources/appliance";
import { ApplianceRelation } from "src/app/dto/resources/appliance-relation";
import { Camera } from "src/app/dto/resources/camera";
import { GPSDevice } from "src/app/dto/resources/gps-device";
import { Personnel } from "src/app/dto/resources/personnel";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceSkill } from "src/app/dto/resources/skill";
import { ResourceState } from "src/app/dto/resources/state";
import { Station } from "src/app/dto/resources/station";
import { SkillUserRelation } from "src/app/dto/user/skill-user-relation";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { UserService } from "../user/user.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { IncidentService } from "src/app/incident/incident.service";
import { WGSPoint } from "src/app/dto/map/location";
import { BufferProvider } from "src/app/global/automation/buffer/buffer.provider";
import { ConfigurationService } from "../types/configuration.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LANGUAGE } from "src/app/global/constants/enums/languages";
import { Subject } from "rxjs";
import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { User } from "src/app/dto/user/user";
import { ResourceLogEntry } from "src/app/dto/resources/resource-log-entry";
import { BUFFER_ITEM_TYPE } from "src/app/dto/replay/replay-buffer";

@Injectable({
	providedIn: "root"
})
export class ResourceService {
	public text: () => LocaleMap;

	/**
    * List of the agents in the current mission
    * @property missionResources
    * @type {Array}

    var missionResources = new Array();*/
	/**
	 * List of cameras
	 * @property Cameras
	 * @type {Array}
	 */
	public Cameras: Array<Camera> = new Array();
	/**
	 * List of GPS gps devices
	 * @property GPSDevices
	 * @type {Array}
	 */
	public GPSDevices: Array<GPSDevice> = new Array();
	public States: Array<ResourceState> = new Array();
	public Skills: Array<ResourceSkill> = new Array();
	public Stations: Array<Station> = new Array();
	public ApplianceRelations: Array<ApplianceRelation> = new Array();
	public ClosedApplianceRelations: Array<ApplianceRelation> = new Array();
	public telemetryData: Array<any> = new Array();
	/**
	 * List of the currently active agents.
	 * @property Resources
	 * @type {Array}
	 */
	public Resources: Array<Resource> = new Array();
	public activeResources: Array<Resource> = new Array();

	public _hwCamerasMissing = false;
	public _hwGPSMissing = false;
	public cameraUpdate$ = new Subject<{ id: number; action: "update" | "delete" }>();
	public stateUpdate$ = new Subject<{ id: number; action: "update" | "delete" }>();
	public needsTelemetry: boolean = false;

	public positionUpdate$ = new Subject<Resource>();

	private readonly wreq: WebRequestFactory;
	private readonly conf: ConfigurationService;
	private readonly mssg: MessagingService;
	private readonly user: UserService;
	private readonly ems: IncidentService;
	private readonly bufferProv: BufferProvider;
	private skillRelations: Array<SkillUserRelation> = new Array();

	private readonly gpsTimeouts = new Map<number, number>();

	private readonly GPS_ICON_TIMEOUT_MS = 20000;

	public constructor(wreq: WebRequestFactory, conf: ConfigurationService, mssg: MessagingService, user: UserService, ems: IncidentService, bp: BufferProvider, textProv: TextProvider) {
		this.wreq = wreq;
		this.conf = conf;
		this.mssg = mssg;
		this.user = user;
		this.ems = ems;
		this.bufferProv = bp;
		this.text = textProv.getStringMap;
		textProv.languageChange.subscribe((langs: { previous: LANGUAGE; next: LANGUAGE }) => {
			this.Resources.forEach((res) => {
				if (res.station === textProv.strings.get(langs.previous)!.UNSPECIFIED) res.station = textProv.strings.get(langs.next)!.UNSPECIFIED;
			});
		});

		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_USERS, this.checkUserUpdates);
		this.user.$userChanged.subscribe(this.checkUserUpdate);
		this.cameraUpdate$.subscribe((update: { id: number; action: string }) => {
			if (update.action === "delete") {
				const index = this.Cameras.findIndex((cam) => cam.id === update.id);
				index !== -1 && this.Cameras.splice(index, 1);
			}
		});
	}


	public readonly updateClosedRelations: Function = async () => {
		const ans: Array<string> = await this.wreq.getClosedApplianceRelations();
		if (!ans || ans.length === 0) return;

		if (ans && ans.length > 0) {
			DTOArray.UpdateFromJsonArray(this.ClosedApplianceRelations, ans, ApplianceRelation);
			this.ClosedApplianceRelations.forEach(this.setupClosedAppliance as (value: ApplianceRelation, index: number, array: ApplianceRelation[]) => void);
		}
	};

	public readonly getAgentChange: Function = async (id: number) => {
		const jsonUpdate: string = await this.wreq.getResourceById(id);
		if (!jsonUpdate) return undefined;
		let agent = Resource.fromJson(jsonUpdate);
		agent = agent.is_appliance ? Appliance.fromJson(jsonUpdate) : Personnel.fromJson(jsonUpdate);
		if (agent.deleted) {
			this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
			return agent;
		}
		const old = this.Resources.find((e) => e.id === agent.id);
		if (!old) {
			if (!agent.is_appliance) (agent as Personnel).__user = await this.getUserFromAgent(agent as Personnel);
			this.Resources.push(agent);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
			return agent;
		}

		this.checkAndUpdatePosition(old, agent.position);
		CloneFactory.cloneProperties(old, agent);

		if (!old.is_appliance) {
			if (!(old as Personnel).__user) (old as Personnel).__user = await this.getUserFromAgent(old as Personnel);
			(old as Personnel).id_user = (old as Personnel).__user?.id;
		}
		this.addCameraToResource(old);
		this.addTypeToAgent(old);
		this.assignSkills(old);

		if (!agent.is_appliance) {
			const rel = this.ApplianceRelations.find((e) => e.id_personnel === agent.id);
			if (rel) this.mssg.fire(MESSAGE_TYPE.NEW_APPL_REL, rel);
		}
		this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, old);
		return old;
	};

	public async getResourceById(id: number): Promise<Resource | undefined> {
		let resource = await this.wreq.getResourceById(id);
		if (resource && resource.length) return Resource.fromJson(resource);
		return;
	}

	/**
	 * Updates agent information from the webservice.
	 *
	 * @method updateResources
	 * @return {Object} promise of Agent list.
	 */
	public readonly updateResources: Function = async (success: boolean, forceRequest?: boolean) => {
		if (success) {
			const mission = this.ems.getCurrentIncident();
			const isPlayback = !!mission && mission.closed
			this.needsTelemetry = false;
			let jsonArray = new Array<string>();
			if (forceRequest) jsonArray = await this.wreq.getAllResources();
			else {
				const mission = this.ems.getCurrentIncident();
				jsonArray = await this.wreq.getAllResources(isPlayback);
			}
			if (!jsonArray) return false;
			let temp: Array<Resource> = [];
			this.Resources.length = 0;
			DTOArray.UpdateFromJsonArray(temp, jsonArray, Resource);
			temp.forEach((agent) => {
				if (!agent.is_appliance) {
					let person = new Personnel(
						agent.id,
						agent.id_incident,
						agent.latitude,
						agent.longitude,
						agent.id_camera,
						agent.type,
						agent.name,
						agent.description,
						agent.telemetry_id,
						agent.id_gps_device,
						agent.deleted,
						agent.timestamp,
						agent.fixed_position,
						agent.public_id,
						agent.id_state,
						agent.color,
						agent.icon_path,
						agent.icon_width,
						agent.icon_height,
						agent.track_color,
						agent.station
					);
					if (person.station === "Unspecified") person.station = this.text().UNSPECIFIED; //TO CHECK-WORKING IN SPANISH
					this.addTypeToAgent(person);
					this.addCameraToResource(person);
					this.Resources.push(person);
				} else {
					let appl = new Appliance(
						agent.id,
						agent.id_incident,
						agent.latitude,
						agent.longitude,
						agent.id_camera,
						agent.type,
						agent.name,
						agent.description,
						agent.telemetry_id,
						agent.id_gps_device,
						agent.deleted,
						agent.timestamp,
						agent.fixed_position,
						agent.public_id,
						agent.id_state,
						agent.color,
						agent.icon_path,
						agent.icon_width,
						agent.icon_height,
						agent.track_color,
						agent.station
					);
					if (appl.station === "Unspecified") appl.station = this.text().UNSPECIFIED; //TO CHECK-WORKING IN SPANISH
					this.addTypeToAgent(appl);
					this.addCameraToResource(appl);
					this.Resources.push(appl);
				}
			});
			if (jsonArray.length === 0) return true; // no meaning in requesting the relations when there's no agents
			const ans: string[] = await this.wreq.getApplianceRelationExtended(-1, isPlayback);
			if (!ans) return false;
			if (!this.ApplianceRelations) this.ApplianceRelations = [];
			DTOArray.UpdateFromJsonArray(this.ApplianceRelations, ans, ApplianceRelation);
			this.ApplianceRelations.forEach((rel) => this.setupAppliance(rel));
			this.activeResources = this.Resources.filter((e) => !e.deleted);
			this.mssg.fire(MESSAGE_TYPE.LOAD_AGENTS, this.Resources);
			return true;
		} else return Promise.resolve(success);
	};

	public readonly updateCameras: Function = async () => {
		//update regardless of success of milestone update
		const jsonArray: Array<string> = await this.wreq.getAllCameras();
		if (!jsonArray) return false;
		let temp: Array<Camera> = [];
		DTOArray.UpdateFromJsonArray(temp, jsonArray, Camera, this.addCameraToResource);
		DTOArray.UpdateFromJsonArray(this.Cameras, jsonArray, Camera, this.addCameraToResource);
		if (this._hwCamerasMissing) this.Resources.forEach((res) => this.addCameraToResource(res));
		return true;
	};

	public readonly getCameraById: Function = async (id: number) => {
		const json: string = await this.wreq.getCameraById(id);
		if (!json) return false;
		let cam = Camera.fromJson(json);
		let oldCam = this.Cameras.find((c) => c.id == cam.id);
		if (!oldCam) this.Cameras.push(cam);
		else {
			CloneFactory.cloneProperties(oldCam, cam);
		}
		this.cameraUpdate$.next({ id: cam.id, action: "update" });
		return cam;
	};

	public readonly updateStates: Function = async (success: boolean) => {
		if (success) {
			const jsonArray: Array<string> = await this.wreq.getStates();
			if (jsonArray) DTOArray.UpdateFromJsonArray(this.States, jsonArray, ResourceState);
			return true;
		} else return success;
	};

	public readonly getStateById: Function = async (id: number) => {
		const jsonArr: string = await this.wreq.getStates(id);
		if (!jsonArr || !jsonArr[0]) return false;
		let state = ResourceState.fromJson(jsonArr[0]);
		let old = this.States.find((c) => c.id == state.id);
		if (!old) this.States.push(state);
		else {
			CloneFactory.cloneProperties(old, state);
		}
		this.stateUpdate$.next({ id: state.id, action: "update" });
		return state;
	};

	public removeStateUpdate(state_id: number): void {
		const idx = this.States.findIndex((e) => e.id === state_id);
		if (idx > -1) {
			this.States.splice(idx, 1);
			this.stateUpdate$.next({ id: state_id, action: "delete" });
		}
	}

	public readonly updateSkills: Function = async (success: boolean) => {
		if (success) {
			const jsonArray: Array<string> = await this.wreq.getSkills();
			if (jsonArray) DTOArray.UpdateFromJsonArray(this.Skills, jsonArray, ResourceSkill);
			const jsonArray2 = await this.wreq.getUserSkillRelations();
			if (jsonArray2 && jsonArray2.length > 0) {
				DTOArray.UpdateFromJsonArray(this.skillRelations, jsonArray2, SkillUserRelation);
				this.Resources.forEach(this.assignSkills as (value: Resource, index: number, array: Resource[]) => void);
			}
			return true;
		}
		return false;
	};

	public readonly newSkillRelationUpdate: Function = async (id: number) => {
		const json: string = await this.wreq.getUserSkillRelation(id);
		if (json && json.length > 0) {
			let ans = SkillUserRelation.fromJson(json[0]);
			if (!this.skillRelations.find((e) => e.id == ans.id)) {
				this.skillRelations.push(ans);
				let agent = this.Resources.find((agent) => agent.id == ans.id_user);
				if (agent) {
					if (!agent.skills) agent.skills = new Array();
					if (!agent.skills.find((e) => e.id == ans.id_skill)) agent.skills.push(this.Skills.find((skill) => skill.id == ans.id_skill) as ResourceSkill);
					this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
				}
			}
			return ans;
		}
		return false;
	};

	public readonly deleteSkillRelationUpdate: Function = (id: number) => {
		const idx = this.skillRelations.findIndex((e) => e.id == id);
		const agent = this.Resources.find((e) => e.id == this.skillRelations[idx].id_user);
		if (agent && agent.skills) {
			const idx2 = agent.skills.findIndex((e) => e.id == this.skillRelations[idx].id_skill);
			if (idx2 > -1) {
				agent.skills.splice(idx2, 1);
				this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
			}
		}
		if (idx > -1) this.skillRelations.splice(idx, 1);
	};

	public readonly updateGPSDevices: Function = async (success: boolean) => {
		if (success) {
			const jsonArray: Array<string> = await this.wreq.getGPSDevices();
			if (jsonArray) DTOArray.UpdateFromJsonArray(this.GPSDevices, jsonArray, GPSDevice);
			return true;
		} else return success;
	};
	public readonly loadPreBuffer: Function = async (success: boolean) => {
		success = await this.updateMilestoneCameras(success);
		success = await this.updateCameras(success);
	};

	public readonly loadBuffer: Function = (success: boolean) => {
		return this.updateResources(success);
	};

	public readonly load: Function = async (success: boolean) => {
		success = await this.updateCameras(success);
		success = await this.updateStations(success);
		success = await this.updateStates(success);
		success = await this.updateMilestoneCameras(success);
		success = await this.updateResources(success, true);
		success = await this.updateGPSDevices(success);
		success = await this.updateSkills(success);
		success = await this.updateClosedRelations(success);
		this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT_SERVICE);
		return success;
	};
	public readonly loadMission = (success: boolean): boolean => {
		return !!success;
	};

	public readonly unload: Function = (success: boolean) => {
		this.Resources.length = 0;
		this.Cameras.length = 0;
		this.GPSDevices.length = 0;
		this.States.length = 0;
		this.Skills.length = 0;
		this.Stations.length = 0;
		this.ApplianceRelations.length = 0;
		this.ClosedApplianceRelations.length = 0;
	};

	/*
'Gorseinon',
        'Pontardulais',
        'Morriston',
        'Swansea Central',
        'Port Talbot',
        'Llanelli'
        */
	public readonly updateStations: Function = async (success: boolean) => {
		const ans: Array<string> = await this.wreq.getAllStations();
		if (ans) {
			let stats = new Array();
			this.Stations.length = 0;
			DTOArray.UpdateFromJsonArray(this.Stations, ans, Station);
			return true;
		} else return false;
	};

	/**
	 * Sends request to webservice to delete agent from server
	 *
	 * @method deleteAgent
	 * @param {Int} id Id of agent to delete.
	 * @return {Boolean} Whether the action was successfull or not.
	 */
	public readonly deleteAgent: Function = async (agent: Resource) => {
		const success: boolean = await this.wreq.deleteAgent(agent.id);
		if (success) {
			DTOArray.DeleteById(this.Resources, agent.id);
			this.mssg.fire(MESSAGE_TYPE.LOAD_AGENTS, this.Resources);

			return true;
		} else return false;
	};

	// called by the automation service, agent already deleted so must update local data
	public readonly deleteAgentById: Function = (id: number) => {
		const agent = this.Resources.find((e) => e.id == id);
		if (!agent) return false;
		agent.deleted = true;
		this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
		return true;
	};
	/**
	 * Searches and returns agent from its telemetry id.
	 *
	 * @method getAgentByTelemetryId
	 * @param {Int} id Id of the telemetry object of the agent
	 * @return {Agent} The agent object.
	 */
	public readonly getAgentByTelemetryId: Function = (id: number) => {
		for (let i = 0; i < this.Resources.length; i++) if (this.Resources[i].telemetry_id == id) return this.Resources[i];
		return null;
	};
	/**
	 * Save agent to server
	 *
	 * @method saveAgent
	 * @param {Agent} agent Agent to save in server
	 * @return {Agent} The agent as saved in server
	 */
	public readonly saveAgent: Function = async (agent: Resource) => {
		const hasUserPermissions = this.user.getCurrentUserLevel()?.users_manage;
		agent.public_id = agent.name.substring(0, 4);
		const newAgent = agent.id === -1 ? (agent as Personnel) : false; //to conserve the original user??
		const oldAgent = this.Resources.find((e) => e.id == agent.id);
		const agentJson: string = await this.wreq.saveResource(agent);
		if (agentJson) {
			const ans = Resource.fromJson(agentJson);
			//	oldAgent && oldAgent.is_appliance && oldAgent.id_mission && oldAgent.id_mission === -1 && ans.id_mission > -1 && (await this.createLogEntry(ans as Appliance));
			if (newAgent) {
				if (newAgent.__user) newAgent.__user!.id_resource = ans.id;
				newAgent.id = ans.id;
				const newPersonnel = new Personnel(-1, -1, -1, -1, -1, -1, "");
				CloneFactory.cloneProperties(newPersonnel, newAgent);
				this.Resources.push(newPersonnel);
				if (!ans.is_appliance) {
					await this.saveUserAndSkills(newPersonnel);
				}
				agent = newPersonnel;
			} else {
				this.addTypeToAgent(ans);
				this.addCameraToResource(ans);
				this.assignSkills(ans);
				CloneFactory.cloneProperties(oldAgent, ans);
				if (!agent.is_appliance && hasUserPermissions) {
					await this.saveUserAndSkills(agent as Personnel);
					(oldAgent as Personnel).__user = (agent as Personnel).__user;
				}
			}
			this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
			return agent;
		} else return false;
	};

	public readonly createLogEntry = async (appl: Appliance): Promise<void> => {
		const resourceLog: ResourceLogEntry[] = [];
		const logs = await this.wreq.getAgentLog();
		DTOArray.UpdateFromJsonArray(resourceLog, await logs, ResourceLogEntry);
		const log = resourceLog.find((e) => e.id_agent === appl.id && e.id_mission === appl.id_incident);
		!log && (await this.wreq.saveResourceLog(new ResourceLogEntry(-1, appl.id, appl.id_incident, Date.now(), -1, -1, -1)));
	};

	// a simple save, it saves a Resource and doesn't save anything else (if Personnel, it wouldn't save its User or its skill assignment)
	public readonly moveResource = async (res: Resource): Promise<boolean> => {
		const agentJson: string = await this.wreq.saveResource(res);
		if (agentJson) return true;
		return false;
	};

	public readonly addResourceToIncident: Function = async (agent: Resource, id_mission: number) => {
		//requestFunction = adding ? wreq.addAgentToMission : wreq.removeAgentFromMission;

		const agentJson: string = await this.wreq.addResourceToIncident(agent, id_mission);
		if (agentJson) {
			const ag = Resource.fromJson(agentJson);
			this.addTypeToAgent(agent);
			this.addCameraToResource(agent);
			agent.id_incident = ag.id_incident;
			if (!agent.is_appliance) (agent as Personnel).__user = await this.getUserFromAgent(agent as Personnel);
			if (!this.Resources.find((e) => e.id == agent.id)) {
				this.Resources.push(agent);
			}
			this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, ag);
			return ag;
		} else return false;
	};

	public readonly saveApplianceRelation: Function = async (appl_rel: ApplianceRelation) => {
		const ans: string = await this.wreq.saveApplianceRelation(appl_rel);

		if (!appl_rel || appl_rel.id_appliance < 0 || appl_rel.id_personnel < 0) {
			return false;
		}

		if (ans) {
			let newRel = ApplianceRelation.fromJson(ans);
			appl_rel.id = newRel.id;
			this.setupAppliance(appl_rel);
			this.ApplianceRelations.push(appl_rel);
			this.mssg.fire(MESSAGE_TYPE.NEW_APPL_REL, appl_rel);
			return appl_rel;
		}
		return false;
	};

	// only unique values are relation id and person id
	public readonly getApplianceRelation: Function = (id_relation: number | undefined, id_personnel?: number) => {
		if (id_relation) return this.ApplianceRelations.find((e) => e.id === id_relation);
		if (id_personnel) return this.ApplianceRelations.find((e) => e.id_personnel === id_personnel);
		return undefined;
	};

	public readonly newAppRelationUpdate: Function = async (id: number) => {
		const ans: Array<string> = await this.wreq.getApplianceRelationExtended(id);
		if (ans && ans.length) {
			if (ans.length == 0) return false;
			let newRel = ApplianceRelation.fromJson(ans[0]);
			let oldRel = this.ApplianceRelations.find((rel) => rel.id == id);
			if (!oldRel) {
				this.ApplianceRelations.push(newRel);
				this.setupAppliance(newRel);
			} else {
				if (oldRel.id_appliance !== newRel.id_appliance) {
					// a relation can only change if a person goes from appliance to another, so no need to check more
					oldRel.id = -1;
					this.setupAppliance(oldRel);
					this.setupAppliance(newRel);
				}
				oldRel.id = newRel.id;
				oldRel.id_appliance = newRel.id_appliance;
				oldRel.id_personnel = newRel.id_personnel;
			}

			// this check is done because if the resource doesn't exist we'll fire the event when
			// the resource gets loaded instead (getAgentChange method)
			if (this.Resources.find((e) => e.id === newRel.id_personnel)) this.mssg.fire(MESSAGE_TYPE.NEW_APPL_REL, newRel);
			return newRel;
		}
		return false;
	};

	public readonly deleteAppRelationUpdate: Function = (id: number) => {
		const idx = this.ApplianceRelations.findIndex((e) => e.id === id);

		if (!id) return;

		if (idx > -1) {
			const rel = this.ApplianceRelations[idx];
			rel.id = -1;
			this.setupAppliance(rel);
			this.ApplianceRelations.splice(idx, 1);
			this.mssg.fire(MESSAGE_TYPE.DELETE_APPL_REL, rel);
		}
	};

	public readonly purgeRelationsFromApp: Function = (id: number) => {
		let appliance = this.Resources.find((appl) => appl.id == id) as Appliance;
		if (!appliance) return;

		appliance.personnel = [];
		appliance.oic = undefined;
		let age = this.Resources.find((e) => e.id == id);
		if (age) {
			(age as Appliance).personnel = [];
			(age as Appliance).oic = undefined;
		} else {
			for (let i = 0; i < this.ApplianceRelations.length; i++) {
				if (this.ApplianceRelations[i].id_appliance == id) {
					this.mssg.fire(MESSAGE_TYPE.DELETE_APPL_REL, this.ApplianceRelations[i]);
					this.ApplianceRelations.splice(i, 1);
					i--;
				}
			}
		}
	};

	public readonly deleteApplianceRelation: (id_relation: number | undefined, id_appl?: number, id_person?: number) => Promise<boolean> = async (id_relation, id_appl?, id_person?) => {
		if (!id_relation) {
			let relation;
			if (id_appl) relation = this.ApplianceRelations.find((e) => e.id_appliance === id_appl);
			if (id_person) relation = this.ApplianceRelations.find((e) => e.id_personnel === id_person);
			if (!relation) return false;
			id_relation = relation.id;
		}
		if (!id_relation) return false;
		const ans: boolean = await this.wreq.deleteApplianceRelation(id_relation);
		if (ans) {
			const idx = this.ApplianceRelations.findIndex((e) => e.id === id_relation);
			if (idx > -1) {
				let rel = this.ApplianceRelations[idx];
				this.ApplianceRelations.splice(idx, 1);
				if (rel) {
					const appl = this.Resources.find((e) => e.id === rel.id_appliance) as Appliance;
					appl &&
						appl.personnel.splice(
							appl.personnel.findIndex((e) => e.id === rel.id_personnel),
							1
						);
					let person = this.Resources.find((e) => e.id === rel.id_personnel);
					if (appl.oic && appl.oic.id === person!.id) appl.oic = undefined;
					if (person) (person as Personnel).__appliance = undefined;
				}
				this.mssg.fire(MESSAGE_TYPE.DELETE_APPL_REL, rel);
			}
			return true;
		}
		return false;
	};

	/**
	 * Save state to server
	 */
	public readonly saveState = async (state: ResourceState): Promise<ResourceState | boolean> => {
		const stateJson: string = await this.wreq.saveState(state);
		if (stateJson) {
			const state = ResourceState.fromJson(stateJson);
			DTOArray.AddFromJson(this.States, stateJson, ResourceState);
			return state;
		} else return false;
	};

	public readonly deleteState: Function = async (state: ResourceState) => {
		const success: boolean = await this.wreq.deleteState(state.id);
		if (success) {
			DTOArray.DeleteById(this.States, state.id);
			return true;
		} else return false;
	};

	public readonly addSkillToAgent: Function = async (relation: SkillUserRelation) => {
		const success: string = await this.wreq.saveUserSkillRelation(relation);
		if (success) {
			relation = SkillUserRelation.fromJson(success);
			this.skillRelations.push(relation);
			let age = this.Resources.find((agent) => agent.id == relation.id_user);
			if (!age) return { success: true };
			if (!age.skills) age.skills = new Array();
			if (!age.skills.find((e) => e.id == relation.id_skill)) age.skills.push(this.Skills.find((e) => e.id == relation.id_skill)!);
			return { success: true, agent: age, skill: this.Skills.find((e) => e.id == relation.id_skill) };
		}
		return { success: false };
	};

	public readonly checkAgentSkill: (resourceId: number, skillId: number) => SkillUserRelation | undefined = (resourceId, skillId) => {
		return this.skillRelations.find((rel) => rel.id_user == resourceId && rel.id_skill == skillId);
	};

	public readonly removeSkillFromAgent: Function = async (resourceId: number, skillId: number) => {
		const skillToRemove = this.checkAgentSkill(resourceId, skillId);
		const success: boolean = await this.wreq.removeUserSkillRelation(skillToRemove?.id);
		if (success) {
			let agent = this.Resources.find((agent) => agent.id == resourceId);
			if (!agent) return { success: true };
			if (!agent.skills) return { success: true, agent: agent };
			let idx = agent.skills.findIndex((skill) => skill.id == skillId);
			if (idx > -1) agent.skills.splice(idx, 1);
			return { success: true, agent: agent, skill: this.Skills.find((e) => e.id == skillId) };
		}
		return { success: false };
	};

	public readonly getUserFromAgent = async (agent: Personnel): Promise<User | undefined> => {
		const localUser = this.user.Users.find((e) => e.id_resource == agent.id);
		if (localUser) return Promise.resolve(localUser);
		await this.user.updateUsers();
		return Promise.resolve(this.user.Users.find((e) => e.id_resource == agent.id));
	};

	/**
	 * Save camera to server
	 *
	 * @method saveCamera
	 * @param {Camera} camera Camera to save
	 * @return {Camera} The camera as saved in server
	 */
	public readonly saveCamera: Function = async (camera: Camera) => {
		const cameraJson: string = await this.wreq.saveRtmpCamera(camera);
		if (cameraJson) {
			const camera = Camera.fromJson(cameraJson);
			DTOArray.AddFromJson(this.Cameras, cameraJson, Camera);
			if (camera) this.addCameraToResource(camera);
			return camera;
		} else return false;
	};

	public readonly deleteCamera: Function = async (camera: Camera) => {
		const success: boolean = await this.wreq.deleteCamera(camera.id);
		if (success) {
			DTOArray.DeleteById(this.Cameras, camera.id);
			return true;
		} else return false;
	};
	public readonly saveGPSDevice: Function = async (device: GPSDevice) => {
		const deviceJson: string = await this.wreq.saveGPSDevice(device);
		if (deviceJson) {
			return DTOArray.AddFromJson(this.GPSDevices, deviceJson, GPSDevice);
		} else return false;
	};

	public readonly deleteGPSDevice: Function = async (device: GPSDevice) => {
		const success: boolean = await this.wreq.deleteGPSDevice(device.id);
		if (success) {
			DTOArray.DeleteById(this.GPSDevices, device.id);
			return true;
		} else return false;
	};

	public readonly freeAppliance: (id_appl: number) => Promise<void> = async (id_appl: number) => {
		const appl = this.Resources.find((e) => e.id === id_appl) as Appliance;
		if (!appl || !appl.is_appliance || !appl.personnel.length) return;
		const personnelArr = [...appl.personnel];
		for (let i = 0; i < personnelArr.length; i++) {
			const rel = this.getApplianceRelation(null, personnelArr[i].id);
			await this.deleteApplianceRelation(rel.id);
		}
		return;
	};

	public readonly getAgentTrack: (agent: Resource) => Array<WGSPoint> = (agent) => {
		if (!this.bufferProv.buffered) return [];
		const jsonArr = this.bufferProv.buffer.getAllValues(BUFFER_ITEM_TYPE.RESOURCE, Date.now());
		if (!jsonArr) return [];
		const ans = new Array<WGSPoint>();
		for (let i = 0; i < jsonArr.length; i++) {
			const item = Resource.fromJson(jsonArr[i]);
			if (item.id === agent.id && (item.latitude !== 0 || item.longitude !== 0)) {
				const newPoint = new WGSPoint(item.latitude, item.longitude);
				newPoint.timestamp = item.timestamp || Date.now();
				const oldPoint = ans[ans.length - 1];
				if (!oldPoint || oldPoint.latitude !== newPoint.latitude || oldPoint.longitude !== newPoint.longitude) ans.push(newPoint);
			}
		}
		return ans;
	};



	private readonly assignSkills: Function = (agent: Personnel) => {
		agent.skills = this.Skills.filter((skill) => this.skillRelations.find((e) => e.id_skill === skill.id && e.id_user === agent.id));
	};


	private readonly checkUserUpdates: Function = () => {
		this.user.Users.forEach(this.checkUserUpdate as (value: User, index: number, array: User[]) => void);
	};

	private readonly checkUserUpdate = (user: User): void => {
		if (user.id_resource && user.id_resource > -1) {
			const agent = this.Resources.find((e) => e.id == user.id_resource);
			if (agent && !agent.is_appliance) {
				(agent as Personnel).__user = user;
				(this.Resources.find((e) => e.id == user.id_resource)! as Personnel).__user = user;
				this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
			}
		}
	};

	private readonly addTypeToAgent: Function = async (agent: Resource) => {
		const type = this.conf.configuration.resourceTypes.find((e) => e.id === agent.type);
		agent.__typeObj = type;

		if (!agent.fixed_position) this.needsTelemetry = true;

		const camera = this.Cameras.find((e) => e.id === agent.id_camera);
		agent.__camera = camera;

		const state = this.States.find((e) => e.id === agent.id_state);
		agent.__state = state ? state : new ResourceState(-1, this.text().UNSPECIFIED);

		if (!agent.is_appliance) {
			const user = await this.getUserFromAgent(agent as Personnel);
			(agent as Personnel).__user = user;
			if (user) (agent as Personnel).id_user = user.id;
		}
		//if (!Stations.find(stat => stat == agent.station) && agent.station !== 'Unspecified') Stations.push(agent.station); // constructs the station array, placed here for efficiency purposes
	};

	private readonly addCameraToResource: Function = (agent: Resource) => {
		if(agent.id_camera === -1) return;
		agent.__camera = this.Cameras.find((e) => e.id === agent.id_camera);
		this._hwCamerasMissing = this._hwGPSMissing = false;
		return true;
	};
	private readonly updateMilestoneCameras: Function = async (success: boolean) => {
		if (success) {
			const ans = await this.wreq.updateMilestoneCameras();
			return ans;
		} else return Promise.resolve(false);
	};

	private readonly saveUserAndSkills = async (agent: Personnel): Promise<void> => {
		agent.__user = await this.user.saveUser(agent.__user);
		agent.id_user = agent.__user!.id;
		if (agent.skills) {
			agent.skills.forEach((skill) => {
				this.addSkillToAgent(new SkillUserRelation(-1, skill.id, agent.id));
			});
		}
		// this.mssg.fire(MESSAGE_TYPE.UPDATE_AGENT, agent);
	};

	private readonly setupAppliance: Function = (appl_rel: ApplianceRelation) => {
		// sets the references of the Agent objects according to the relation defined on the ApplianceRelation object
		// if the relation id is -1 it deletes them
		if (appl_rel.id_appliance < 0 || appl_rel.id_personnel < 0) return false;
		const appl = this.Resources.find((appl) => appl.id === appl_rel.id_appliance) as Appliance;
		const person = this.Resources.find((agent) => agent.id === appl_rel.id_personnel) as Personnel;

		if (!appl || !appl.is_appliance || !person || person.is_appliance) {
			return false;
		}
		if (appl_rel.id > -1) {
			if (!appl.personnel.find((e) => e.id === appl_rel.id_personnel)) {
				if (!appl_rel.oic) appl.personnel.push(person as Personnel);
				else appl.oic = person as Personnel;
			}
			(person as Personnel).__appliance = appl;
		} else {
			const idx = appl.personnel.findIndex((e) => e.id === appl_rel.id_personnel);
			appl.personnel.splice(idx, 1);
			(person as Personnel).__appliance = undefined;
		}
		let age = this.Resources.find((e) => e.id === appl!.id);
		if (age) {
			(age as Appliance).personnel = appl.personnel;

			if (appl.oic && !appl.personnel.find((e) => e.id === appl.oic!.id)) {
				appl.personnel.push(appl.oic); // Add the OIC to the personnel list
			}
			(age as Appliance).oic = appl.oic;
		}

		age = this.Resources.find((e) => e.id == person!.id);
		if (age) (age as Personnel).__appliance = appl;
		return appl;
	};

	private readonly setupClosedAppliance: Function = (closed_appl_rel: ApplianceRelation) => {
		// only sets the appliance closed_personnel array
		if (closed_appl_rel.id_appliance < 0 || closed_appl_rel.id_personnel < 0) {
			return false;
		}

		const appliance = this.Resources.find((res) => res.id === closed_appl_rel.id_appliance) as Appliance;
		const personnel = this.Resources.find((res) => res.id === closed_appl_rel.id_personnel) as Personnel;
		
		if (!appliance || !personnel) return false;
		if(!appliance.closed_personnel) appliance.closed_personnel = [];
		if(!appliance.closed_oics) appliance.closed_oics = [];
		if (appliance) {
			if (!appliance.closed_personnel.find((e) => e.object.id === closed_appl_rel.id_personnel && e.id_incident === closed_appl_rel.id_mission)) {
				appliance.closed_personnel.push({ object: personnel as Personnel, id_incident: closed_appl_rel.id_mission! });
			}

			if (closed_appl_rel.oic) {
				appliance.closed_oics.push({object: personnel, id_incident: closed_appl_rel.id_mission! });
			}
		}
		return true;
	};

	private readonly checkAndUpdatePosition = (agent: Resource, position: WGSPoint): void => {
		if (agent.position.latitude !== position.latitude || agent.position.longitude !== position.longitude) {
			agent.__gps_signal = true;
			agent.position = position;
			agent.latitude = agent.position.latitude;
			agent.longitude = agent.position.longitude;
			if (this.gpsTimeouts.get(agent.id)) {
				clearTimeout(this.gpsTimeouts.get(agent.id));
			}
			const idt = window.setTimeout(() => {
				agent.__gps_signal = false;
				this.positionUpdate$.next(agent);
			}, this.GPS_ICON_TIMEOUT_MS);
			this.gpsTimeouts.set(agent.id, idt);
			this.bufferProv.insertResourcePosition(agent);
			this.positionUpdate$.next(agent);
		}
	};
}

export abstract class GPS_ICON {
	public static readonly PATH =
		"M 149.83203 16.359375 C 144.27503 16.359375 139.77148 20.866875 139.77148 26.421875 C 139.77148 31.976875 144.27403 36.480469 149.83203 36.480469 C 210.53603 36.482469 259.92188 85.87589 259.92188 146.58789 C 259.92188 152.14289 264.42742 156.64844 269.98242 156.64844 C 275.53842 156.64844 280.04297 152.14289 280.04297 146.58789 C 280.04197 74.78089 221.62903 16.361375 149.83203 16.359375 z M 48.261719 56.908203 C 47.810719 56.908203 47.356344 56.940953 46.902344 57.001953 C 43.787344 57.427953 41.050906 59.284484 39.503906 62.021484 C 8.0489066 117.70349 17.692125 188.20956 62.953125 233.47656 C 89.962125 260.49056 125.87517 275.36714 164.07617 275.36914 L 164.08398 275.36914 C 188.72998 275.36914 213.04067 268.99169 234.38867 256.92969 C 237.12567 255.38269 238.9842 252.64625 239.4082 249.53125 C 239.8342 246.41625 238.77669 243.28159 236.55469 241.05859 L 55.375 59.855469 C 53.477 57.955469 50.911719 56.908203 48.261719 56.908203 z M 149.83398 70.09375 C 144.27698 70.09375 139.77344 74.597344 139.77344 80.152344 C 139.77344 85.709344 144.27698 90.212891 149.83398 90.212891 C 180.91198 90.211891 206.19822 115.50189 206.19922 146.58789 C 206.19922 152.14489 210.70377 156.64844 216.25977 156.64844 C 221.81677 156.64844 226.31836 152.14289 226.31836 146.58789 C 226.31836 104.40989 192.00598 70.09375 149.83398 70.09375 z M 144.93945 116.76758 C 141.98764 116.76797 139.03466 117.14338 136.16016 117.89062 L 178.37305 160.10742 C 181.36005 148.60842 178.37672 135.86505 169.38672 126.87305 C 162.64572 120.13055 153.79489 116.76641 144.93945 116.76758 z ";
	public static readonly COLOR = {
		ACTIVE: "#4da805",
		INACTIVE: "#cb1b02"
	};
	public static readonly SCALE = 1 / 22;
}

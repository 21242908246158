import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { JesipCard, JESIP_CARD_BODY_TYPE, JESIP_CARD_INITIAL_COLOR } from "../jesip-card.interface";
import { IncidentService } from "src/app/incident/incident.service";

@Component({
	selector: "app-void-jesip-card",
	templateUrl: "void-jesip-card.component.html",
	styleUrls: ["../jesip-card.css", "void.css"]
})
export class VoidJesipCardComponent implements JesipCard {
	@Input() initial: string | undefined;
	@Input() title: string = "";
	@Input() bodyText: string | (() => string) | undefined;
	@Input() buttonText: string | (() => string) | undefined;
	@Input() color: string | (() => string) = JESIP_CARD_INITIAL_COLOR.BLUE;
	@Input() backgroundSrc: string = "";
	@Input() updateAvailable: boolean = false;

	@Output() buttonClick = new EventEmitter<Event>();

	public readonly type = JESIP_CARD_BODY_TYPE.VOID;

	public readonly text: () => LocaleMap;
	private readonly ems: IncidentService;

	constructor(textProv: TextProvider, ems: IncidentService) {
		this.text = textProv.getStringMap;
		this.ems = ems;
	}

	public readonly getBody: () => string = () => {
		if (!this.bodyText) return "";
		if (typeof this.bodyText === "string") return this.bodyText;
		return this.bodyText();
	};

	public readonly getButton: () => string = () => {
		if (!this.buttonText) return "";
		if (typeof this.buttonText === "string") return this.buttonText;
		return this.buttonText();
	};

	public readonly getColour: () => string = () => {
		if (typeof this.color === "string") return this.color;
		return this.color();
	};
	public isIncidentClosed(): boolean {
		const currentIncident = this.ems.getCurrentIncident();
		return currentIncident!.closed;
	}
}
